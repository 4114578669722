// Generated by Framer (9bf39e5)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useActiveVariantCallback, useRouteElementId, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {m9APJ1Eix: {hover: true}};

const cycleOrder = ["m9APJ1Eix"];

const variantClassNames = {m9APJ1Eix: "framer-v-1oqehd2"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {"m9APJ1Eix-hover": {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 0.8, stiffness: 232, type: "spring"}, default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `translate(-50%, -50%) ${t}`

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string; tap?: any }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "m9APJ1Eix", title: AzQLEVigC = "Kapcsolat", tap: OUxoh9mmm, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "m9APJ1Eix", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1iq55bt = activeVariantCallback(async (...args) => {
if (OUxoh9mmm) {
const res = await OUxoh9mmm(...args);
if (res === false) return false;
}
})

const id1 = useRouteElementId("Xa9O2Rhg8", "pH3J1KNIf")

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-h5756", classNames)} style={{display: "contents"}}>
<Link href={{hash: id1, webPageId: "pH3J1KNIf"}} openInNewTab={false} smoothScroll><motion.a {...restProps} className={`${cx("framer-1oqehd2", className)} framer-do7kd1`} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"m9APJ1Eix"} onTap={onTap1iq55bt} ref={ref} style={{backgroundColor: "rgb(51, 136, 255)", borderBottomLeftRadius: 9, borderBottomRightRadius: 9, borderTopLeftRadius: 9, borderTopRightRadius: 9, ...style}} transition={transition} variants={{"m9APJ1Eix-hover": {backgroundColor: "rgb(0, 53, 128)"}}} {...addPropertyOverrides({"m9APJ1Eix-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7TW9udHNlcnJhdC03MDA=", "--framer-font-family": "\"Montserrat\", serif", "--framer-font-size": "18px", "--framer-font-weight": "700", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv)"}}>Kapcsolat</motion.p></React.Fragment>} className={"framer-1rwsyvf"} fonts={["GF;Montserrat-700"]} layoutDependency={layoutDependency} layoutId={"b5VZBQccW"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={AzQLEVigC} transformTemplate={transformTemplate} transition={transition} verticalAlignment={"top"} withExternalLayout/></motion.a></Link>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-h5756 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-h5756 * { box-sizing: border-box; }", ".framer-h5756 .framer-do7kd1 { display: block; }", ".framer-h5756 .framer-1oqehd2 { cursor: pointer; height: 43px; overflow: hidden; position: relative; text-decoration: none; width: 138px; will-change: transform; }", ".framer-h5756 .framer-1rwsyvf { flex: none; height: auto; left: 50%; position: absolute; top: 51%; white-space: pre; width: auto; }", ".framer-h5756 .framer-v-1oqehd2 .framer-1oqehd2 { cursor: pointer; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 43
 * @framerIntrinsicWidth 138
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"jbn_0FqPb":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"AzQLEVigC":"title","OUxoh9mmm":"tap"}
 */
const FrameratWo4_dLZ: React.ComponentType<Props> = withCSS(Component, css, "framer-h5756") as typeof Component;
export default FrameratWo4_dLZ;

FrameratWo4_dLZ.displayName = "Kapcsolat gomb";

FrameratWo4_dLZ.defaultProps = {height: 43, width: 138};

addPropertyControls(FrameratWo4_dLZ, {AzQLEVigC: {defaultValue: "Kapcsolat", displayTextArea: false, title: "Title", type: ControlType.String}, OUxoh9mmm: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FrameratWo4_dLZ, [{family: "Montserrat", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/atWo4_dLZ:default", url: "https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM70w-Y3tcoqK5.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM70w-Y3tcoqK5.ttf", weight: "700"}])